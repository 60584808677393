import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

import img from "../images/ashok.jpg"
import img1 from "../images/lekha.jpg"

const About = props => {
  // 31/5/2009 16/12/1979
  let dateA = [2009, 5]
  let dateL = [1979, 12]
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth()
  dateA = currentYear - dateA[0]
  dateL = currentYear - dateL[0]
  console.log(currentMonth)
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "Image 4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <h1 id="about" style={{ textAlign: "center" }}>
        About
      </h1>
      <div className="about responsive">
        <div>
          <img
            alt="Advocate Lekha Suresh"
            style={{ width: "50%", height: "auto" }}
            src={img1}
          />
          {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
          <h1>Lekha Suresh</h1>
          <p>B.sc LL.B [Hons]</p>
          <p>{dateL}+ yrs experience</p>
          {/* <p style={{ marginRight: "5%" }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dignissim leo nec nunc lobortis feugiat. Nullam faucibus, nulla nec vehicula sodales, risus ex egestas quam, id efficitur ex nisi eget risus. Curabitur vulputate elit eget bibendum posuere. Cras a posuere turpis. Aliquam mauris neque, mattis ac velit vitae, elementum pretium eros. In posuere erat lectus, eu congue quam vehicula in. Nullam nec gravida orci, ac ornare ipsum. Nam non fringilla sapien, a accumsan ante. Sed accumsan enim erat, at tincidunt ipsum consequat sed. Curabitur eget gravida massa. Proin pretium, ligula ac vulputate congue, enim est lacinia diam, non sagittis dolor urna id urna.</p> */}
        </div>
        <div style={{ textAlign: "right" }}>
          <img
            alt="Advocate Ashok Suresh"
            style={{ right: 0, width: "50%", height: "auto" }}
            src={img}
          />
          {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
          <h1>Ashok Suresh</h1>
          <p>B.A LL.B [Hons]</p>
          <p>{dateA}+ yrs experience</p>
          {/* <p style={{ marginLeft: "5%"}} >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas dignissim leo nec nunc lobortis feugiat. Nullam faucibus, nulla nec vehicula sodales, risus ex egestas quam, id efficitur ex nisi eget risus. Curabitur vulputate elit eget bibendum posuere. Cras a posuere turpis. Aliquam mauris neque, mattis ac velit vitae, elementum pretium eros. In posuere erat lectus, eu congue quam vehicula in. Nullam nec gravida orci, ac ornare ipsum. Nam non fringilla sapien, a accumsan ante. Sed accumsan enim erat, at tincidunt ipsum consequat sed. Curabitur eget gravida massa. Proin pretium, ligula ac vulputate congue, enim est lacinia diam, non sagittis dolor urna id urna.</p> */}
        </div>
      </div>
      <h3 style={{ margin: "2% 5% 5% 5%", textAlign: "justify" }}>
        Advocate Lekha Suresh is a lawyer having an experience of more than{" "}
        {dateL} years. She enrolled as an advocate on 16.12.1979 and started
        practice as a Civil Lawyer before the Honourable High Court of Kerala.
        She is at present practicing before the Honourable High Court of Kerala
        and Family Courts. Her main area of practice is Matrimonial laws.
        <br />
        <br />
        Advocate Ashok Suresh is a lawyer having an experience of more than{" "}
        {dateA} years, enrolled in the year 2009. He is presently
        serving as an Additional Central Government Standing Counsel before the
        Central Administrative Tribunal, Ernakulam Bench. He is at present
        practising before the Honourable High Court of Kerala, Central
        Administrative Tribunal, Ernakulam Bench and Family Courts.
        <br />
        <br />
        They deals with Civil, Criminal, Service and Constitutional Matters
        before the Honourable High Court of Kerala. They also deal with matters
        relating to Divorce, Maintenance, Gaurdianship & Custody of Children,
        Property dispute, Return of gold ornaments & Money Domestic Violence etc
        before the Family Court.
      </h3>
    </div>
  )
}

export default About
