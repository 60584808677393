import React from "react"

const Contact = () => {
    return(
        <div style={{ background: "black" }} > 
            <h1 id="contact" style={{ textAlign: "center", color: "white" }} >Contact</h1>
            <div className="contact responsive" >
                <div className="map">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4706.727872249834!2d76.27234365084419!3d9.984061280970751!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d59b46fd939%3A0x8138a3e5872fce48!2sLekha%20Suresh%20%26%20Ashok%20Suresh%2C%20Advocates!5e0!3m2!1sen!2sin!4v1596530456364!5m2!1sen!2sin" 
                        width="100%" 
                        height="400" 
                        frameborder="0" 
                        style={{ border:0 }} 
                        allowfullscreen="" 
                        aria-hidden="false" 
                        tabindex="0">
                    </iframe>
                </div>
                <div style={{ color: "white" }}>
                    <h3>Chamber No. 116,</h3>
                    <h3>Kerala High Court Advocates Association Chamber Complex,</h3>
                    <h3>Near High Court of Kerala, Ernakulam, Kerala 682031</h3>
                    <h3>+918891662557, +919447374596</h3>
                </div>
            </div>
        </div>
    )
}

export default Contact