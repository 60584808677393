import React from "react"

const Acts = () => {
    return(
        <div className="acts" >
            <div>
                <h1 id="acts">Acts and Rules</h1>
                <div className="links responsive">
                    <div>
                        {/* <a href=""><h2>The Family Courts Act, 1984</h2></a> */}
                        <a href="https://www.indiacode.nic.in/handle/123456789/1844?sam_handle=123456789/1362"><h2>The Family Courts Act, 1984</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/1560?sam_handle=123456789/1362"><h2>The Hindu Marriage Act, 1955</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/2280?sam_handle=123456789/1362"><h2>The Divorce Act, 1869</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/2318?view_type=search&sam_handle=123456789/1362#tb14"><h2>The Guardians and Wards Act, 1890</h2></a>
                    </div>
                    <div>
                        <a href="https://www.indiacode.nic.in/handle/123456789/1649?view_type=search&sam_handle=123456789/1362"><h2>The Hindu Minority And Guardianship Act, 1956</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/2021?sam_handle=123456789/1362"><h2>The Protection Of Women From Domestic Violence Act, 2005</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/1387?view_type=search&sam_handle=123456789/1362"><h2>The Special Marriage Act, 1954</h2></a>
                        <a href="https://www.indiacode.nic.in/handle/123456789/2404?sam_handle=123456789/1362"><h2>The Dissolution of Muslim Marriages Act, 1939</h2></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Acts