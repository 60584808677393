import React from "react"

import "../styles/style.css"

const Front = () => (
        <div className="front" >
            <h1>Lekha Suresh & Ashok Suresh<br></br> Advocates</h1>
            {/* <h1>9447374596</h1> */}
        </div>
    )

export default Front 